import React from "react";
import * as styles from "./footer.module.css"
import { Link, useStaticQuery, graphql } from "gatsby";
import Facebook from "../../../assets/facebook.png"
import Instagram from "../../../assets/instagram.png"


const Footer = () => {
    const data = useStaticQuery(query);

    return <footer>
        <div className={styles.footerNav}>
            <Link to={`/`}>PAGRINDINIS</Link>
            <Link to={`/naujienos`}>NAUJIENOS</Link>
            <Link to={`/dokumentai`}>DOKUMENTAI</Link>
            <Link to={`/varzybos`}>VARŽYBOS</Link>
            <Link to={`/kontaktai`}>KONTAKTAI</Link>
        </div>
        <div className={styles.footerSubNav}>
            {data.allStrapiCategory.edges.map((category, i) => (
                <Link to={`/${category.node.slug}`} key={i}>
                    {category.node.name}
                </Link>
            ))}
        </div>
        <div className={styles.icons}>
            <a href="https://www.instagram.com/lithuaniangymnasticsfederation/" target="_blank" ><img src={Instagram} width={24}></img></a>
            <a href="https://www.facebook.com/Lietuvos-gimnastikos-federacija-Lithuanian-Gymnastics-Federation-127855577993098/" target="_blank" ><img src={Facebook} width={18}></img></a>
        </div>
        <hr></hr>
        <div className={styles.footerCredentials}>
            © {new Date().getFullYear()} Lietuvos gimnastikos federacija
        </div>
    </footer>
};

export default Footer;

const query = graphql`
    query {
        strapiGlobal {
            siteName
        }
        allStrapiCategory {
            edges {
                node {
                    slug
                    name
                }
            }
        }
    }
`;

import React from "react";
import PropTypes from "prop-types";
import Nav from "./nav";
import Seo from "./seo";
import Footer from "./footer/footer";
import { MdKeyboardArrowUp } from "@react-icons/all-files/md/MdKeyboardArrowUp";
export const isBrowser = () => typeof window !== "undefined"

function trackScroll(e) {
  let element = document.getElementById("go-to-top");

  if (window.pageYOffset > 50) {
    element.classList.add("show")
  } else {
    element.classList.remove("show")
  }
}

const Layout = ({ children, seo }) => {
  if (isBrowser()) {
    window.addEventListener('scroll', trackScroll)
  }

  return <div>
    <Nav />
    <main style={{
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '1500px',
      margin: '0 auto',
    }} >{children}</main>
    <Footer />
    <a className="go-to-top" id="go-to-top" onClick={() => window.scrollTo(0, 0)}>
      <MdKeyboardArrowUp size={25} color="white" />
    </a>
  </div>
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import Logo from "../../assets/rsz_11.png"
import { MdMenu } from "@react-icons/all-files/md/MdMenu";
import { MdClose } from "@react-icons/all-files/md/MdClose";
import * as styles from "./nav.module.css"
import { MdExpandMore } from "@react-icons/all-files/md/MdExpandMore";


const Nav = () => (

  <StaticQuery
    query={graphql`
      query {
        strapiGlobal {
          siteName
        }
        allStrapiCategory(sort: {fields: created_at, order: ASC}) {
          edges {
            node {
              slug
              name
            }
          }
        }
      }
    `}
    render={(data) => (
      <>
        <div className={styles.navbarContainer}>
          <div className={styles.firstNavbar}>
            <Link to={`/`}>
              <img src={Logo} className={styles.navbarLogo}></img>
            </Link>

            <div className={styles.navbarDesktop}>
              <Link to={`/`} activeClassName="active">
                Pagrindinis
              </Link>
              <Link to={`/naujienos`} activeClassName={styles.activeFirstNavbar}>
                Naujienos
              </Link>
              <Link to={`/dokumentai`} activeClassName={styles.activeFirstNavbar}>
                Dokumentai
              </Link>
              <Link to={`/varzybos`} activeClassName={styles.activeFirstNavbar}>
                Varžybos
              </Link>
              <Link to={`/kontaktai`} activeClassName={styles.activeFirstNavbar}>
                Kontaktai
              </Link>
            </div>

            <MdMenu size={40} className={styles.menuIcon} onClick={mobileClick} />
          </div>

          <div className={styles.secondNavbar}>
            {data.allStrapiCategory.edges.map((category, i) => (
              <Link key={i} to={`/${category.node.slug}`} activeClassName={styles.activeSecondNavbar}>
                {category.node.name}
              </Link>
            ))}
          </div>
        </div>

        <div className={styles.mobileMenu} id="mobileMenu">
          <div className={styles.mobileNavControl}>
            <img src={Logo} className={styles.navbarLogo} />
            <MdClose onClick={mobileClick} size={40} />
          </div>

          <div className={styles.mobileNavUpper}>
            <Link to={`/`}>
              PAGRINDINIS
          </Link>
            <Link to={`/naujienos`}>
              NAUJIENOS
          </Link>
            <Link to={`/dokumentai`}>
              DOKUMENTAI
          </Link>
            <Link to={`/varzybos`}>
              VARŽYBOS
          </Link>
            <Link to={`/kontaktai`}>
              KONTAKTAI
          </Link>
            <div className={styles.categories} onClick={categoriesClick} >
              <div>GIMNASTIKOS ŠAKOS</div>
              < MdExpandMore style={{ paddingLeft: "5px" }} size={'23'} />
            </div>
            <div className={styles.mobileNavLower} id="categories">
            {data.allStrapiCategory.edges.map((category, i) => (
              <Link key={i} to={`/${category.node.slug}`} activeClassName={styles.activeSecondNavbar}>
                {category.node.name}
              </Link>
            ))}
            </div>
          </div>
          <div />
        </div>
      </>
    )}
  />
);

export default Nav;

function mobileClick() {
  var nav = document.getElementById("mobileMenu");
  if (nav.style.display === "flex") {
    nav.style.display = "none";
  }
  else {
    nav.style.display = "flex"
  }
}

function categoriesClick() {
  var cat = document.getElementById("categories");
  if (cat.style.display === "flex") {
    cat.style.display = "none";
  }
  else {
    cat.style.display = "flex"
  }
}


